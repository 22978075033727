import Box from 'components/Box/Box'
import styled from 'styled-components'

export const Arrow = styled(Box)<{ themeBackground: string }>`
  &,
  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    z-index: -1;
  }

  &::before {
    content: '';
    transform: rotate(45deg);
    background: ${({ themeBackground }) => themeBackground};
  }
`

export const StyledTooltip = styled(Box)`
  padding: 12px;
  font-size: 12px;
  line-height: 130%;
  border-radius: 5px;
  z-index: 101;
  backdrop-filter: blur(5px);
  color: ${({ theme }) => theme.colors.text};

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] > ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] > ${Arrow} {
    left: -4px;
  }
`
