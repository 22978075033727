import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Button from 'components/Button'
import { CopyButton } from 'components/CopyButton'
import { useCurrencySearchModal } from 'components/CurrencySearch/hook'
import CurrencyLogo from 'components/Logo'
import { Token } from 'config/types/token'
import React from 'react'
import styled from 'styled-components'
import { DropdownIcon } from 'svgs'

const StyledButton = styled(Button)`
  background: transparent !important;
  padding: 0px 4px;
  padding-left: 8px;
  font-size: 12px;

  :hover {
    background: rgba(255, 255, 255, 0.05) !important;
  }

  svg {
    margin-left: 0px !important;
    fill: ${({ theme }) => theme.colors.text};
  }

  &:disabled {
    background: rgba(255, 255, 255, 0) !important;
    cursor: default !important;
    color: ${({ theme }) => theme.colors.text};
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 14px;
  }
`

const CurrencySelect: React.FC<{
  token: Token
  otherToken?: Token
  onTokenSelect: (token: Token) => void
  currencies?: Token[]
}> = ({ token, otherToken, currencies, onTokenSelect }) => {
  const { onPressent } = useCurrencySearchModal()
  const handlePressentCurrencySearch = () => {
    onPressent({
      onTokenSelect,
      selectedToken: token,
      otherSelectedToken: otherToken,
      currencies: currencies,
    })
  }

  return (
    <Flex alignItems="center">
      <StyledButton
        startIcon={
          token ? (
            <Box width="24px">
              <CurrencyLogo size={24} token={token} />
            </Box>
          ) : null
        }
        endIcon={!!onTokenSelect && <DropdownIcon style={{ marginLeft: 0 }} />}
        onClick={!!onTokenSelect ? handlePressentCurrencySearch : () => {}}
        disabled={!onTokenSelect}
        mr="6px"
      >
        {token ? token.symbol : 'Select a currency'}
      </StyledButton>
      {token && !token.isNative && <CopyButton width="16px" text={token.address} />}
    </Flex>
  )
}

export default CurrencySelect
