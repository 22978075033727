import React from 'react'
import Button from 'components/Button'
import { ApprovalState } from 'hooks/useApproveCallback'
import Dots from 'components/Loader/Dots'
import Text from 'components/Text'
import { ButtonProps } from './types'

export interface ApproveButtonProps {
  approveCallback: () => Promise<void>
  approval: ApprovalState
}

export const ApproveButton: React.FC<ApproveButtonProps & ButtonProps> = ({
  approveCallback,
  approval,
  disabled,
  children,
  ...props
}) => {
  return (
    <>
      {approval === ApprovalState.APPROVED_LESS_THAN_INPUT_AMOUNT && (
        <Text color="failure" fontWeight="300" small textAlign="left" mb="8px">
          Your amount approved is less than the input amount.
        </Text>
      )}

      <Button
        variant="primary"
        onClick={approveCallback}
        disabled={
          (approval !== ApprovalState.NOT_APPROVED && approval !== ApprovalState.APPROVED_LESS_THAN_INPUT_AMOUNT) ||
          disabled
        }
        {...props}
      >
        {approval === ApprovalState.CONFIRMING ? (
          <Dots>Confirming on wallet</Dots>
        ) : approval === ApprovalState.PENDING ? (
          <Dots>Enabling</Dots>
        ) : (
          children
        )}
      </Button>
    </>
  )
}

export default React.memo(ApproveButton)
