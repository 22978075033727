import React from 'react'
import styled from 'styled-components'
import Input from 'components/Input'
import { escapeRegExp } from 'utils'
import { BoxProps } from 'components/Box/types'
import { TextProps } from 'components/Text/types'

const StyledInput = styled(Input)`
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  font-size: 18px;
  box-shadow: none !important;
`

interface NumericalInputProps {
  value: string
  onUserInput: (value: string) => void
  placeholder?: string
  className?: string
}

const inputRegex = RegExp(`^\\d*(?:\\\\[.]\\d{0,18})?$`) // match escaped "." characters via in a non-capturing group

const NumericalInput = ({ value, onUserInput, placeholder = '0.0', ...props }: NumericalInputProps) => {
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput)
    }
  }

  return (
    <StyledInput
      value={value}
      p={['24px 12px']}
      placeholder={placeholder}
      {...props}
      inputMode="decimal"
      type="text"
      minLength={1}
      maxLength={48}
      pattern="^[0-9]*[.,]?[0-9]*$"
      onChange={(event) => {
        // replace commas with periods, because we exclusively uses period as the decimal separator
        enforcer(event.target.value.replace(/,/g, '.'))
      }}
    />
  )
}

export default React.memo(NumericalInput)
