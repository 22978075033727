import React, { useCallback, useState } from 'react'
import { TransactionResponse } from '@ethersproject/providers'
import ToastDescriptionWithTx from 'components/Toast/DescriptionWithTx'

import useToast from 'hooks/useToast'
import { useTransaction, useTransactionAdder } from 'state/transactions/hooks'
import useActiveWeb3React from './useActiveWeb3React'
import { TransactionDetails } from 'config/types/transaction'

export type TxResponse = TransactionResponse | null

export type CatchTxErrorReturn = {
  fetchWithCatchTxError: (base: string, fn: () => Promise<TxResponse>) => Promise<TxResponse>
  tnxDetails: TransactionDetails
  isWalletConfirming: boolean
}

type ErrorData = {
  code: number
  message: string
}

type TxError = {
  data: ErrorData
  error: string
}

// -32000 is insufficient funds for gas * price + value
const isGasEstimationError = (err: TxError): boolean => err?.data?.code === -32000

export const isUserRejected = (err) => {
  // provider user rejected error code
  return typeof err === 'object' && 'code' in err && (err.code === 4001 || err.code === 'ACTION_REJECTED')
}

export default function useCatchTxError(): CatchTxErrorReturn {
  const { provider, chainId } = useActiveWeb3React()
  const { toastError, toastInfo } = useToast()
  const [txHash, setTxHash] = useState('')
  const [isWalletConfirming, setIsWalletConfirming] = useState(false)
  const tnxDetails = useTransaction(txHash)

  const addTransaction = useTransactionAdder()

  const handleNormalError = useCallback(
    (tx?: TxResponse) => {
      if (tx) {
        const txError = tx as unknown as ErrorData

        if (/L_SS/.test(txError.message) || /slippage_tolerance/.test(txError.message)) {
          return toastError('Error', 'Please increase your slippage')
        }

        toastError(
          'Error',
          <ToastDescriptionWithTx txHash={tx.hash} chainId={chainId}>
            Please try again. Confirm the transaction and make sure you are paying enough gas!
          </ToastDescriptionWithTx>,
        )
      } else {
        toastError('Error', 'Please try again. Confirm the transaction and make sure you are paying enough gas!')
      }
    },
    [toastError],
  )

  const fetchWithCatchTxError = useCallback(
    async (base: string, callTx: () => Promise<TxResponse>): Promise<TxResponse | null> => {
      let tx: TxResponse = null
      setTxHash('')
      try {
        setIsWalletConfirming(true)
        tx = await callTx()
        setIsWalletConfirming(false)
        setTxHash(tx.hash)
        toastInfo(`${'Transaction Submitted'}!`, <ToastDescriptionWithTx txHash={tx.hash} chainId={chainId} />)
        addTransaction(
          tx,
          {
            summary: base,
          },
          chainId,
        )

        return tx
      } catch (error: any) {
        if (!isUserRejected(error)) {
          if (!tx) {
            handleNormalError(error)
          } else {
            provider
              .call(tx, tx.blockNumber)
              .then(() => {
                handleNormalError(tx)
              })
              .catch((err: any) => {
                if (isGasEstimationError(err)) {
                  handleNormalError(tx)
                } else {
                  let recursiveErr = err

                  let reason: string | undefined

                  // for MetaMask
                  if (recursiveErr?.data?.message) {
                    reason = recursiveErr?.data?.message
                  } else {
                    // for other wallets
                    // Reference
                    // https://github.com/Uniswap/interface/blob/ac962fb00d457bc2c4f59432d7d6d7741443dfea/src/hooks/useSwapCallback.tsx#L216-L222
                    while (recursiveErr) {
                      reason = recursiveErr.reason ?? recursiveErr.message ?? reason
                      recursiveErr = recursiveErr.error ?? recursiveErr.data?.originalError
                    }
                  }

                  const REVERT_STR = 'execution reverted: '
                  const indexInfo = reason?.indexOf(REVERT_STR)
                  const isRevertedError = indexInfo >= 0

                  if (isRevertedError) reason = reason.substring(indexInfo + REVERT_STR.length)

                  toastError(
                    'Failed',
                    <ToastDescriptionWithTx txHash={tx.hash} chainId={chainId}>
                      {isRevertedError
                        ? `Transaction failed with error: ${reason}`
                        : 'Transaction failed. For detailed error message:'}
                    </ToastDescriptionWithTx>,
                  )
                }
              })
          }
        }
      } finally {
        setIsWalletConfirming(false)
      }

      return null
    },
    [handleNormalError, toastError, provider, toastInfo, addTransaction, chainId],
  )

  return {
    fetchWithCatchTxError,
    tnxDetails,
    isWalletConfirming,
  }
}
