import Box from 'components/Box/Box'
import React, { useCallback, useEffect } from 'react'
import Button from 'components/Button'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import ConnectButton from 'components/ConnectButton'
import useCatchTxError from 'hooks/useCatchTxError'
import Dots from 'components/Loader/Dots'
import { ButtonProps } from './types'

export interface SubmitTxnButton extends ButtonProps {
  base: string
  call: () => Promise<any>
  callback?: () => void
  error?: string
}

export const SubmitTxnButton: React.FC<SubmitTxnButton> = ({
  base,
  call,
  callback,
  error,
  children,
  disabled,
  ...props
}) => {
  const { fetchWithCatchTxError, tnxDetails, isWalletConfirming } = useCatchTxError()
  const pendingTx = tnxDetails && !tnxDetails.receipt

  useEffect(() => {
    if (tnxDetails) {
      if (tnxDetails.receipt?.status && callback) {
        callback()
      }
    }
  }, [tnxDetails])

  const handleSubmit = useCallback(async () => {
    await fetchWithCatchTxError(base, () => {
      return call()
    })
  }, [base, call])

  return (
    <Button {...props} onClick={handleSubmit} disabled={isWalletConfirming || pendingTx || !!error || disabled}>
      {isWalletConfirming ? <Dots>Confirming on wallet</Dots> : pendingTx ? <Dots>Submiting</Dots> : error || children}
    </Button>
  )
}

export default React.memo(SubmitTxnButton)
