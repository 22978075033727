import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { useCallback, useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTransactionAdder, useHasPendingApproval } from '../state/transactions/hooks'
import { useCall } from './useCall'
import useTokenAllowance from './useTokenAllowance'
import { useTokenContract } from 'hooks/useContract'
import { TokenAmount } from 'config/types/tokenAmount'
import BigNumber from 'bignumber.js'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  APPROVED_LESS_THAN_INPUT_AMOUNT,
  CONFIRMING,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: TokenAmount,
  spender?: string,
): [ApprovalState, () => Promise<void>] {
  const { account } = useActiveWeb3React()
  const { call, walletConfirming } = useCall()
  const { token } = amountToApprove
  const currentAllowance = useTokenAllowance(token, account ?? undefined, spender)
  const pendingApproval = useHasPendingApproval(token?.address, spender)
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    const allowance = currentAllowance ? new BigNumber(currentAllowance?.amount) : null
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN

    if (amountToApprove.token.isNative) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!allowance) return ApprovalState.UNKNOWN
    if (walletConfirming) return ApprovalState.CONFIRMING
    if (pendingApproval) return ApprovalState.PENDING

    if (allowance.eq(0)) return ApprovalState.NOT_APPROVED

    // amountToApprove will be defined if currentAllowance is
    return allowance.lt(amountToApprove.amount) ? ApprovalState.APPROVED_LESS_THAN_INPUT_AMOUNT : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pendingApproval, walletConfirming, spender])

  const tokenContract = useTokenContract(token?.address)
  const addTransaction = useTransactionAdder()

  const approve = useCallback(async (): Promise<void> => {
    if (
      approvalState !== ApprovalState.NOT_APPROVED &&
      approvalState !== ApprovalState.APPROVED_LESS_THAN_INPUT_AMOUNT
    ) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!token) {
      console.error('no token')
      return
    }

    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }

    if (!amountToApprove) {
      console.error('missing amount to approve')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    let useExact = false
    return call(tokenContract, 'approve', [spender, useExact ? amountToApprove.amount : MaxUint256])
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: `Approve ${amountToApprove.token.symbol}`,
          approval: { tokenAddress: token.address, spender },
        })
      })
      .catch((error: Error) => {
        console.error('Failed to approve token', error)
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, addTransaction, call])

  return [approvalState, approve]
}
