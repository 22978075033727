import React, { useState } from 'react'
import { copyToClipboardWithCommand } from 'utils'
import CopyIcon from 'svgs/copy.svg'
import Box from 'components/Box/Box'
import { colors } from 'theme/colors'
import IconStatus from 'components/IconStatus'
import Flex from 'components/Box/Flex'

interface CopyButtonProps {
  text: string
  buttonColor?: string
  width: string
}

export const CopyButton: React.FC<CopyButtonProps> = ({ text, width, buttonColor = colors.textSubtle }) => {
  const [isSuccessed, setIsTooltipDisplayed] = useState(false)

  const displayTooltip = () => {
    setIsTooltipDisplayed(true)
    setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
  }
  return (
    <Box position="relative">
      <Flex alignItems="center" width="18px" height="18px">
        {isSuccessed ? (
          <IconStatus status="success" />
        ) : (
          <CopyIcon
            style={{ cursor: 'pointer' }}
            fill={buttonColor}
            width={width}
            onClick={() => copyToClipboardWithCommand(text, displayTooltip)}
          />
        )}
      </Flex>
    </Box>
  )
}
