import useModal from 'hooks/useModal'

import { useMemo } from 'react'
import CurrencySearchModal from './CurrencySearchModal'
import CurrencySearchBorrowAssetModal from './CurrencySearchBorrowAssetModal'

export const useCurrencySearchModal = () => {
  const [onPressent] = useModal(CurrencySearchModal)
  return useMemo(() => ({ onPressent }), [onPressent])
}

export const useCurrencySearchBorrowAssetModal = () => {
  const [onPressent] = useModal(CurrencySearchBorrowAssetModal)
  return useMemo(() => ({ onPressent }), [onPressent])
}
