import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Text from 'components/Text'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import React, { CSSProperties, MutableRefObject, useCallback, useMemo } from 'react'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components'

import Box from 'components/Box/Box'
import { NATIVE_TOKENS } from 'config/tokenList'
import { Token } from 'config/types/token'
import { TokenAmount } from 'config/types/tokenAmount'
import { useCurrencyBalance } from 'hooks/useTokenBalances'
import Column from 'layout/components/Column'
import { RowBetween, RowFixed } from 'layout/components/Row'
import { displayBalanceEthValue } from 'utils/formatBalance'
import { tokenEquals } from 'utils/tokens'
import CircleLoader from '../Loader/CircleLoader'

const tokenKey = (token: Token): string => {
  return token instanceof Token ? (token.isNative ? NATIVE_TOKENS[token.chainId].symbol : token.address) : ''
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Balance: React.FC<{ balance: TokenAmount }> = ({ balance }) => {
  return (
    <StyledBalanceText title={balance.toExact()}>{displayBalanceEthValue(Number(balance.amount))}</StyledBalanceText>
  )
}

const MenuItem = styled(RowBetween)<{ disabled: boolean; selected: boolean }>`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) minmax(0, 72px);
  grid-gap: 8px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.hover};
  }
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
`

const CurrencyRow = ({
  amount,
  token,
  onSelect,
  isSelected,
  otherSelected,
  style,
}: {
  amount?: TokenAmount
  token: Token
  onSelect: () => void
  isSelected: boolean
  otherSelected: boolean
  style: CSSProperties
}) => {
  const { account, chainId } = useActiveWeb3React()
  const key = tokenKey(token)
  const balance = useCurrencyBalance(amount?._amount?.gt(0) ? null : account ?? undefined, token, chainId)

  const balanceAmount = amount?._amount?.gt(0) ? amount : balance

  return (
    <MenuItem
      style={style}
      className={`token-item-${key}`}
      onClick={() => (isSelected ? null : onSelect())}
      disabled={isSelected}
      selected={otherSelected}
    >
      <Box width="24px">
        <CurrencyLogo token={token} size={24} />
      </Box>
      <Column>
        <Text bold>{token.symbol}</Text>
      </Column>
      <RowFixed style={{ justifySelf: 'flex-end' }}>
        {balanceAmount ? <Balance balance={balanceAmount} /> : account ? <CircleLoader /> : null}
      </RowFixed>
    </MenuItem>
  )
}

const CurrencyList: React.FC<{
  height: number
  currencies: Token[]
  tokenAmounts?: TokenAmount[]
  selectedToken?: Token | null
  onTokenSelect: (token: Token) => void
  otherToken?: Token | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
}> = ({ height, currencies, selectedToken, onTokenSelect, otherToken, fixedListRef, tokenAmounts }) => {
  const { chainId } = useActiveWeb3React()

  const itemData: (Token | undefined)[] = useMemo(() => {
    return [...currencies]
  }, [currencies])

  const Row = useCallback(
    ({ data, index, style }) => {
      const token: Token = data[index]
      const isSelected = Boolean(selectedToken && tokenEquals(selectedToken, token))
      const otherSelected = Boolean(otherToken && tokenEquals(otherToken, token))
      const handleSelect = () => onTokenSelect(token)
      const tokenAmount = tokenAmounts?.find((tokenAmount) => tokenAmount.equalTo(new TokenAmount(token, 0)))

      return (
        <CurrencyRow
          key={token?.address}
          style={style}
          token={token}
          isSelected={isSelected}
          onSelect={handleSelect}
          otherSelected={otherSelected}
          amount={tokenAmount}
        />
      )
    },
    [chainId, onTokenSelect, otherToken, selectedToken],
  )

  const itemKey = useCallback((index: number, data: any) => tokenKey(data[index]), [])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={itemData}
      itemCount={itemData.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}

export default CurrencyList
