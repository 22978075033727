import Heading from 'components/Heading/Heading'
import { ModalBody, ModalHeader, ModalProps } from 'components/Modal'
import Modal from 'components/Modal/Modal'
import { Token } from 'config/types/token'
import { useCallback } from 'react'
import styled from 'styled-components'
import CurrencySearchBorrow from './CurrencySearchBorrow'
import { TokenAmount } from 'config/types/tokenAmount'

const StyledModalBody = styled(ModalBody)`
  padding: 24px;

  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

interface TokenSearchModalProps {
  selectedToken?: Token | null
  onTokenSelect: (token: Token) => void
  otherSelectedToken?: Token | null
  currencies?: Token[]
  tokenAmounts?: TokenAmount[]
}

const CurrencySearchBorrowAssetModal: React.FC<ModalProps<TokenSearchModalProps>> = ({
  onDismiss,
  data: { onTokenSelect, selectedToken, otherSelectedToken, currencies, tokenAmounts },
}) => {
  const handleCurrencySelect = useCallback(
    (token: Token) => {
      onDismiss()
      onTokenSelect(token)
    },
    [onDismiss, onTokenSelect],
  )

  return (
    <Modal minWidth="320px" onDismiss={onDismiss}>
      <ModalHeader>
        <Heading>Select a Token</Heading>
      </ModalHeader>
      <StyledModalBody>
        <CurrencySearchBorrow
          onTokenSelect={handleCurrencySelect}
          selectedToken={selectedToken}
          otherSelectedToken={otherSelectedToken}
          currencies={currencies}
          tokenAmounts={tokenAmounts}
        />
      </StyledModalBody>
    </Modal>
  )
}
export default CurrencySearchBorrowAssetModal
