import { ZERO_ADDRESS } from 'config'
import { Token } from 'config/types/token'
import { compareStringValue } from './addressHelpers'

export function tokenEquals(currencyA: Token, currencyB: Token): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return (
      compareStringValue(currencyA.address, currencyB.address) && compareStringValue(currencyA.symbol, currencyB.symbol)
    )
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export const isTokenNative = (token: Token) => {
  return compareStringValue(token.address, ZERO_ADDRESS) || token.isNative
}
