import BigNumber from 'bignumber.js'
import { Token } from 'config/types/token'
import { TokenAmount } from 'config/types/tokenAmount'
import { useAllTokenBalances } from 'hooks/useTokenBalances'
import { useMemo } from 'react'

// compare two token amounts with highest one coming first
export const balanceComparator = (balanceA?: TokenAmount, balanceB?: TokenAmount) => {
  if (balanceA && balanceB) {
    return balanceA.greaterThan(balanceB) ? -1 : balanceA.equalTo(balanceB) ? 0 : 1
  }

  if (balanceA && new BigNumber(balanceA?.amount).gt(0)) {
    return -1
  }
  if (balanceB && new BigNumber(balanceA?.amount).gt(0)) {
    return 1
  }
  return 0
}

const getTokenComparator = (balances: {
  [tokenAddress: string]: TokenAmount | undefined
}): ((tokenA: Token, tokenB: Token) => number) => {
  return function sortTokens(tokenA: Token, tokenB: Token): number {
    // -1 = a is first
    // 1 = b is first

    // sort by balances)
    const balanceA = balances[tokenA.address]
    const balanceB = balances[tokenB.address]

    const balanceComp = balanceComparator(balanceA, balanceB)
    if (balanceComp !== 0) return balanceComp

    if (tokenA.symbol && tokenB.symbol) {
      // sort by symbol
      return tokenA.symbol.toLowerCase() < tokenB.symbol.toLowerCase() ? -1 : 1
    }
    return tokenA.symbol ? -1 : tokenB.symbol ? -1 : 0
  }
}

export const useTokenComparator = (inverted: boolean): ((tokenA: Token, tokenB: Token) => number) => {
  const balances = useAllTokenBalances()
  const comparator = useMemo(() => getTokenComparator(balances ?? {}), [balances])

  return useMemo(() => {
    if (inverted) {
      return (tokenA: Token, tokenB: Token) => comparator(tokenA, tokenB) * -1
    }
    return comparator
  }, [inverted, comparator])
}
