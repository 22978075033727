import { ChainIdEnum } from 'config/networks'

export class Token {
  chainId: ChainIdEnum
  name: string
  address: string
  isNative: boolean
  decimals: number
  symbol: string
  projectLink?: string
  tokenLogo?: string
}
