import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import Flex from 'components/Box/Flex'
import BigNumber from 'bignumber.js'
import Button from 'components/Button'
import { TokenAmount } from 'config/types/tokenAmount'
import { BIG_ZERO } from 'config'
import ButtonGroupItem from 'components/Button/ButtonGroupItem'

interface QuickFillBalanceProps {
  balance: TokenAmount
  value: string
  onUserInput: (value: string) => void
  onMax: () => void
}

const options = [
  { label: '25%', value: 0.25 },
  { label: '50%', value: 0.5 },
  { label: '75%', value: 0.75 },
  { label: '100%', value: 1 },
]

const QuickFillBalance = ({ balance, onUserInput, onMax, value }: QuickFillBalanceProps) => {
  const handleFill = useCallback(
    (percent: number) => {
      if (percent === 1) {
        onMax()
        return
      }
      const amount = new BigNumber(balance.amount)
      const _value = amount.multipliedBy(percent)
      const valueStr = _value.gte(0) ? _value.toFixed(balance.token.decimals) : '0'
      const valueNumber = Number(valueStr)
      onUserInput(new BigNumber(valueStr).eq(valueNumber) ? valueNumber.toString() : valueStr)
    },
    [balance, onUserInput, onMax],
  )

  const valuePerBalance = useMemo(() => {
    const balanceAmount = new BigNumber(balance?.amount)
    const _value = new BigNumber(value)

    if (balance?.token.isNative) {
      const maxNativeCanFill = balanceAmount.minus(BIG_ZERO)
      if (_value.gte(maxNativeCanFill) && _value.lte(balanceAmount)) return 1
    }
    return Number(_value.div(balanceAmount).toFixed(3))
  }, [balance, value])

  return (
    <Flex p="0px 12px" justifyContent="space-between" mt="12px">
      {options.map((item) => (
        <ButtonGroupItem
          key={item.value}
          $active={valuePerBalance === item.value}
          onClick={() => handleFill(item.value)}
          disabled={!balance}
        >
          {item.label}
        </ButtonGroupItem>
      ))}
    </Flex>
  )
}

export default React.memo(QuickFillBalance)
