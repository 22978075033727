import { useCallback, useState } from 'react'
import { TransactionResponse } from '@ethersproject/providers'
import { Contract, CallOverrides } from '@ethersproject/contracts'
import get from 'lodash/get'

export function useCall() {
  const [walletConfirming, setWalletConfirming] = useState(false)

  /**
   * Perform a contract call with a gas price returned from useGasPrice
   * @param contract Used to perform the call
   * @param methodName The name of the method called
   * @param methodArgs An array of arguments to pass to the method
   * @param overrides An overrides object to pass to the method. gasPrice passed in here will take priority over the price returned by useGasPrice
   * @returns https://docs.ethers.io/v5/api/providers/types/#providers-TransactionReceipt
   */
  const call = useCallback(
    async (
      contract: Contract,
      methodName: string,
      methodArgs: any[] = [],
      overrides: CallOverrides = null,
    ): Promise<TransactionResponse> => {
      try {
        setWalletConfirming(true)
        const contractMethod = get(contract, methodName)

        const tx = await contractMethod(...methodArgs, { ...overrides })
        setWalletConfirming(false)
        return tx
      } catch (e) {
        setWalletConfirming(false)
        throw e
      }
    },
    [],
  )

  return { call, walletConfirming }
}
