import Box from 'components/Box/Box'
import Input from 'components/Input'
import Text from 'components/Text'
import { Token } from 'config/types/token'
import useDebounce from 'hooks/useDebounce'
import { KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FixedSizeList } from 'react-window'

import { isAddress } from '@ethersproject/address'
import CurrencyList from 'components/CurrencyList'
import { NATIVE_TOKENS } from 'config/tokenList'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useAllTokens } from 'hooks/useTokenBalances'
import Column, { AutoColumn } from 'layout/components/Column'
import { RowBetween, RowMiddle } from 'layout/components/Row'
import { filterTokens, useSortedTokensByQuery } from './filtering'
import { useTokenComparator } from './sorting'
import { TokenAmount } from 'config/types/tokenAmount'

interface CurrencySearchProps {
  selectedToken?: Token | null
  onTokenSelect: (token: Token) => void
  otherSelectedToken?: Token | null
  currencies?: Token[]
  tokenAmounts?: TokenAmount[]
}

const CurrencySearchBorrowAsset: React.FC<CurrencySearchProps> = ({
  selectedToken,
  onTokenSelect,
  otherSelectedToken,
  currencies,
  tokenAmounts,
}) => {
  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()
  const { chainId } = useActiveWeb3React()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedQuery = useDebounce(searchQuery, 200)

  const [invertSearchOrder] = useState<boolean>(false)

  const allTokens = useAllTokens()
  const NATIVE_TOKEN = useMemo(() => NATIVE_TOKENS[chainId], [chainId])

  const tokenComparator = useTokenComparator(invertSearchOrder)

  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(currencies || Object.values(allTokens), debouncedQuery)
  }, [currencies, allTokens, debouncedQuery])

  const sortedTokens: Token[] = useMemo(() => {
    return filteredTokens.sort(tokenComparator)
  }, [filteredTokens, tokenComparator])

  const filteredSortedTokens = useSortedTokensByQuery(sortedTokens, debouncedQuery)

  const handleTokenSelect = useCallback(
    (token: Token) => {
      onTokenSelect(token)
    },
    [onTokenSelect],
  )

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    fixedList.current?.scrollTo(0)
  }, [])

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
        if (s === NATIVE_TOKEN.symbol.toLowerCase()) {
          handleTokenSelect(NATIVE_TOKEN)
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() === debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleTokenSelect(filteredSortedTokens[0])
          }
        }
      }
    },
    [filteredSortedTokens, handleTokenSelect, debouncedQuery],
  )

  return (
    <Box minHeight="500px">
      <AutoColumn gap="16px">
        <RowMiddle>
          <Input
            height="50px"
            id="token-search-input"
            placeholder="Search name or paste address"
            autoComplete="off"
            value={searchQuery}
            ref={inputRef as RefObject<HTMLInputElement>}
            onChange={handleInput}
            onKeyDown={handleEnter}
          />
        </RowMiddle>
      </AutoColumn>
      {filteredSortedTokens?.length > 0 ? (
        <Box margin="24px -12px">
          <RowBetween p="4px 20px">
            <Text>Asset Borrow</Text>
            <Text>Maximum Borrow</Text>
          </RowBetween>
          <CurrencyList
            height={390}
            currencies={filteredSortedTokens}
            onTokenSelect={handleTokenSelect}
            otherToken={otherSelectedToken}
            selectedToken={selectedToken}
            fixedListRef={fixedList}
            tokenAmounts={tokenAmounts}
          />
        </Box>
      ) : (
        <Column style={{ padding: '20px', height: '100%' }}>
          <Text color="textSubtle" textAlign="center" mb="20px">
            No results found.
          </Text>
        </Column>
      )}
    </Box>
  )
}

export default CurrencySearchBorrowAsset
