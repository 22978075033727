import { Token } from 'config/types/token'
import { useMemo } from 'react'
import { useTokenContract } from 'hooks/useContract'

import { useSingleCallResult } from '../state/multicall/hooks'
import { TokenAmount } from 'config/types/tokenAmount'
import { BIG_TEN } from 'config'
import BigNumber from 'bignumber.js'

function useTokenAllowance(token?: Token, owner?: string, spender?: string): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)
  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(token?.isNative ? null : contract, 'allowance', inputs).result

  return useMemo(
    () =>
      token && allowance
        ? new TokenAmount(token, new BigNumber(allowance.toString()).div(BIG_TEN.pow(token.decimals)).toString(10))
        : undefined,
    [token, allowance],
  )
}

export default useTokenAllowance
