import { CHAIN_MAINNET, ChainIdEnum } from 'config/networks'
import { Token } from 'config/types/token'
import { useEffect, useMemo, useState } from 'react'
import { multicallv2 } from 'utils/multicall'
import { isAddress } from '@ethersproject/address'
import { FAST_INTERVAL } from 'config'
import { getMulticallAddress } from 'utils/addressHelpers'
import useActiveWeb3React from './useActiveWeb3React'
import { TokenAmount } from 'config/types/tokenAmount'
import { useMulticallContract } from './useContract'
import { useMultipleContractSingleData, useSingleContractMultipleData } from 'state/multicall/hooks'
import { NATIVE_TOKENS } from 'config/tokenList'
import { getBalanceAmount } from 'utils/formatBalance'
import { TOKENS } from 'config/tokens'
import { ERC20_ABI, IERC20, MULTICALL_ABI } from 'config/abi'

async function fetchBalances(account: string, tokenAddresses: string[], chainId: ChainIdEnum) {
  const calls = tokenAddresses.map((address) => {
    return {
      address: address,
      name: 'balanceOf',
      params: [account],
    }
  })

  const results = await multicallv2(ERC20_ABI, calls, chainId, {})

  return tokenAddresses.reduce<{ [tokenAddress: string]: string }>((memo, address, i) => {
    const value = results?.[i]?.[0]
    if (value) {
      memo[address] = value.toString()
    }
    return memo
  }, {})
}

async function fetchNativeBalance(account: string, chainId: ChainIdEnum) {
  const call = {
    address: getMulticallAddress(chainId),
    name: 'getEthBalance',
    params: [account],
  }
  const results = await multicallv2(MULTICALL_ABI, [call], chainId, {})
  return results[0]
}

/**
 * Returns a map of the given addresses to their eventually consistent Native balances.
 */
export function useNativeBalances(uncheckedAddresses?: string | undefined, chainID = CHAIN_MAINNET): TokenAmount {
  const multicallContract = useMulticallContract()

  const address: string = useMemo(() => {
    const validatedAddress = isAddress(uncheckedAddresses)
    return validatedAddress !== false ? uncheckedAddresses : ''
  }, [uncheckedAddresses])

  const result = useSingleContractMultipleData(multicallContract, 'getEthBalance', address ? [[address]] : [])

  return useMemo(() => {
    const value = result?.[0]?.result?.[0]
    const token = NATIVE_TOKENS[chainID]

    if (!token) return null

    return value ? new TokenAmount(token, getBalanceAmount(value.toString(), token.decimals)) : null
  }, [address, result])
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[],
  chainID = CHAIN_MAINNET,
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens],
  )

  const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.address), [validatedTokens])

  const balances = useMultipleContractSingleData(validatedTokenAddresses, IERC20, 'balanceOf', [address])

  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              if (value) {
                memo[token.address] = new TokenAmount(token, getBalanceAmount(value.toString(), token.decimals))
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances],
    ),
    anyLoading,
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[],
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Token | undefined)[],
  chainId = CHAIN_MAINNET,
): (TokenAmount | undefined)[] {
  const tokens = useMemo(() => currencies?.filter((token) => token?.address) ?? [], [currencies])

  const tokenBalances = useTokenBalances(account, tokens)
  const containsNative: boolean = useMemo(() => tokens?.some((currency) => currency.isNative) ?? false, [tokens])
  const nativeBalance = useNativeBalances(containsNative ? account : '', chainId)

  return useMemo(
    () =>
      tokens?.map((token: Token) => {
        if (!account || !token) return undefined
        if (token.isNative) return nativeBalance
        if (token.address) return tokenBalances[token.address]
        return undefined
      }) ?? [],
    [account, currencies, nativeBalance, tokenBalances],
  )
}

export function useCurrencyBalance(account?: string, currency?: Token, chainId?: ChainIdEnum): TokenAmount | undefined {
  return useCurrencyBalances(account, [currency], chainId)[0]
}

export const useAllTokens = (): { [address: string]: Token } => {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => TOKENS[chainId], [chainId])
}

export function useAllTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useActiveWeb3React()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}

export function useERC20TokenBalances(
  address: string,
  tokens: (Token | undefined)[],
  chainId: ChainIdEnum,
): { [tokenAddress: string]: TokenAmount | undefined } {
  const { chainId: defaultChainId } = useActiveWeb3React()
  const isChainDifferent = defaultChainId !== chainId
  const [balances, setBalances] = useState<{ [tokenAddress: string]: string }>({})

  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens],
  )

  const tokenDefaultChainBalances = useTokenBalances(
    isChainDifferent ? undefined : address,
    isChainDifferent ? [] : validatedTokens,
  )

  const serializedtokenAdressesArray = validatedTokens.map((item) => item.address).join(',')

  useEffect(() => {
    const tokenAddresses = serializedtokenAdressesArray.split(',')
    if (!address || !serializedtokenAdressesArray || !isChainDifferent) {
      setBalances({})
      return
    }
    const fetchBalance = async () => {
      const results = await fetchBalances(address, tokenAddresses, chainId)
      setBalances(results)
    }

    fetchBalance()
    const interval = setInterval(fetchBalance, FAST_INTERVAL)
    return () => {
      clearInterval(interval)
    }
  }, [serializedtokenAdressesArray, chainId, address])

  return useMemo(() => {
    return isChainDifferent
      ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount }>((memo, token) => {
          if (balances[token.address]) {
            memo[token.address] = new TokenAmount(
              token,
              getBalanceAmount(balances[token.address].toString(), token.decimals),
            )
          }
          return memo
        }, {})
      : tokenDefaultChainBalances
  }, [validatedTokens, balances, isChainDifferent, tokenDefaultChainBalances])
}

export function useNativeBalance(): TokenAmount {
  const { chainId, account } = useActiveWeb3React()
  const defaultNativeChainBalance = useNativeBalances(account, chainId)

  return useMemo(() => defaultNativeChainBalance, [chainId, account, defaultNativeChainBalance])
}

export function useTokenBalance(address: string, token: Token | undefined): TokenAmount {
  const isNative: boolean = useMemo(() => token.isNative ?? false, [token])
  const tokenBalance = useERC20TokenBalances(address, isNative ? [] : [token], token.chainId)
  const nativeBalance = useNativeBalance()
  return isNative ? nativeBalance : tokenBalance[token.address]
}
