import BigNumber from 'bignumber.js'
import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import { BoxProps } from 'components/Box/types'
import Skeleton from 'components/Skeleton'
import Text from 'components/Text'
import { Token } from 'config/types/token'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import CurrencySelect from 'components/CurrencySelect'
import { BIG_ZERO } from 'config'
import { TokenAmount } from 'config/types/tokenAmount'
import useWeb3 from 'hooks/useWeb3'
import { formatNumber } from 'utils/formatBalance'
import NumericalInput from './NumericalInput'
import QuickFillBalance from './QuickFillBalance'

const InputPanel = styled(Box)`
  background: ${({ theme }) => theme.colors.input};
  border-radius: ${({ theme }) => theme.radii.small};
`

const StyledBalance = styled(Text)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`

interface TokenInputProps extends BoxProps {
  value: string
  onUserInput: (value: string) => void
  disabledQuickFill?: boolean

  onTokenSelect?: (token: Token) => void
  token?: Token | null
  otherToken?: Token | null
  currencies?: Token[]

  balance?: TokenAmount
  balanceLabel?: string
  maxBalance?: string
  hideBalance?: boolean
}

const TokenInput: React.FC<TokenInputProps> = ({
  value,
  onUserInput,
  token,
  otherToken,
  currencies,
  balance,
  maxBalance = '',
  balanceLabel = 'Balance',
  disabledQuickFill,
  onTokenSelect,
  hideBalance = false,
  ...props
}) => {
  const { account } = useWeb3()

  const onMax = useCallback(() => {
    if (!balance?.amount) return

    let _value = new BigNumber(balance.amount)

    if (balance.token.isNative) {
      _value = _value.minus(BIG_ZERO)
    }

    const valueStr = _value.gte(0) ? _value.toFixed(balance.token.decimals) : '0'

    const valueNumber = Number(valueStr)
    if (maxBalance && _value.gt(maxBalance)) {
      return onUserInput(maxBalance)
    }
    onUserInput(new BigNumber(valueStr).eq(valueNumber) ? valueNumber.toString() : valueStr)
  }, [balance, onUserInput])

  return (
    <InputPanel background="input" p={['12px 6px']} {...props}>
      <Flex alignItems="center" justifyContent="space-between">
        <CurrencySelect token={token} otherToken={otherToken} currencies={currencies} onTokenSelect={onTokenSelect} />

        <StyledBalance fontSize="12px" color="textSubtle" pr="12px" display="flex" onClick={onMax}>
          {account && token && !hideBalance ? (
            <>
              {balanceLabel}:{' '}
              {balance ? <>{formatNumber(Number(balance.amount), 1, 6)} </> : <Skeleton width="64px" ml="8px" />}
            </>
          ) : (
            <>---</>
          )}
        </StyledBalance>
      </Flex>
      <NumericalInput className="numerical" value={value} onUserInput={onUserInput} />
      {!disabledQuickFill && account && token && (
        <QuickFillBalance onMax={onMax} balance={balance} value={value} onUserInput={onUserInput} />
      )}
    </InputPanel>
  )
}

export default TokenInput
